import { Component, Input, forwardRef, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { AvatarType } from '../../pipes/avatar.pipe';

function loadFile(file: Blob): Promise<FileReader> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = (err) => reject(err);
    reader.onload = (_) => resolve(reader);
    reader.readAsDataURL(file);
  });
}

function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = (err) => reject(err);
    img.onload = (_) => resolve(img);
    img.src = src;
  });
}

function toBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) return reject(new Error('Failed to generate blob'));
        resolve(blob);
      },
      'image/jpg',
      0.75
    );
  });
}

const MAX_DIMENSION_PX = 500;
async function reduceFileSize(originalFile: File, image: HTMLImageElement): Promise<File> {
  const maxSize = Math.max(image.naturalWidth, image.naturalHeight);
  if (maxSize <= MAX_DIMENSION_PX) return originalFile;

  const canvas = document.createElement('canvas');
  if (image.naturalWidth > image.naturalHeight) {
    canvas.width = MAX_DIMENSION_PX;
    canvas.height = (canvas.width * image.naturalHeight) / image.naturalWidth;
  } else {
    canvas.height = MAX_DIMENSION_PX;
    canvas.width = (canvas.height * image.naturalWidth) / image.naturalHeight;
  }
  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  const blob = await toBlob(canvas);
  return new File([blob], 'profile-picture.png');
}

@Component({
  selector: 'cok-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ImageUploadComponent),
    },
  ],
})
export class ImageUploadComponent implements ControlValueAccessor {
  public faImage = faImage;

  @Input() previewUrl: string | undefined;

  @Input() avatarType: AvatarType | undefined;

  @ViewChild('maxFileSizeModal')
  public maxFileSizeModal: TemplateRef<any>;

  @ViewChild('invalidExtensionModal')
  public invalidExtensionModal: TemplateRef<any>;

  @ViewChild('invalidImageModal')
  public invalidImageModal: TemplateRef<any>;

  onChange: Function;

  constructor(private modalService: BsModalService, private ref: ChangeDetectorRef) {}

  @Input()
  public isEnabled: boolean = true;

  private modalRef: BsModalRef | undefined;

  writeValue(value: null) {}

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}

  setDisabledState(isDisabled: boolean): void {
    this.isEnabled = !isDisabled;
  }

  public closeModal() {
    if (this.modalRef) this.modalRef.hide();
  }

  public async showPreviewImage($event: any) {
    if ($event.target.files && $event.target.files[0]) {
      const file: File = $event.target.files[0];

      if (file.size > 25 * 1024 * 1024) {
        this.modalRef = this.modalService.show(this.maxFileSizeModal, { class: 'modal-sm modal-dialog-centered' });
        return;
      }

      const fileExt = file.name.split('.').pop()!.toLocaleLowerCase();
      if (['jpg', 'jpeg', 'gif', 'png'].indexOf(fileExt) < 0) {
        this.modalRef = this.modalService.show(this.invalidExtensionModal, { class: 'modal-sm modal-dialog-centered' });
        return;
      }

      try {
        const reader = await loadFile(file);
        const dataUrl = reader.result as string;
        const img = await loadImage(dataUrl);
        const output = await reduceFileSize(file, img);

        this.previewUrl = dataUrl;
        this.ref.detectChanges();
        this.onChange(output);
      } catch (err) {
        console.error(err);
        this.modalRef = this.modalService.show(this.invalidImageModal, { class: 'modal-sm modal-dialog-centered' });
      }
    }
  }
}
