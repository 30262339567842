import { gql } from 'apollo-angular';
import { ENGAGEMENT_DETAIL_FRAGMENT } from '../../person/fragments/engagement';

export const DASHBOARD_SELECTIONS_QUERY = gql`
  query dashboardSelections($teamId: Int!) {
    users(teamId: $teamId) {
      id
      name
      picture
      caseRoles {
        id
        userId
        caseId
        role
        case {
          id
          teamId
          createdBy {
            id
          }
        }
      }
    }
    cases {
      id
      caseFileNumber
      teamId
      person {
        id
        firstName
        fullName
        picture
      }
      caseStatus {
        id
        representsClosure
      }
    }
    schema {
      caseStatus {
        id
        name
        sortOrder
      }
    }
  }
`;

export const DASHBOARD_MEMBERS_QUERY = gql`
  query dashboardMembers(
    $teamId: Int!
    $teamMemberIds: [Int!]!
    $caseIds: [Int!]!
    $activities: [String!]!
    $caseStatus: [Int!]!
    $dateStart: Date!
    $dateEnd: Date!
  ) {
    dashboardMembers(
      teamId: $teamId
      teamMemberIds: $teamMemberIds
      caseIds: $caseIds
      activities: $activities
      caseStatus: $caseStatus
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      teamMember {
        id
        firstName
        lastName
        name
        picture
      }
      engagementsData {
        notes
        calls
        emails
        documents
        letters
        texts
        in_person
        social_media
      }
      connectionsData
    }
  }
`;

export const DASHBOARD_CASES_QUERY = gql`
  query dashboardCases(
    $teamId: Int!
    $teamMemberIds: [Int!]!
    $filterFormerTeamMembers: Boolean!
    $caseIds: [Int!]!
    $activities: [String!]!
    $caseStatus: [Int!]!
    $dateStart: Date!
    $dateEnd: Date!
  ) {
    dashboardCases(
      teamId: $teamId
      teamMemberIds: $teamMemberIds
      filterFormerTeamMembers: $filterFormerTeamMembers
      caseIds: $caseIds
      activities: $activities
      caseStatus: $caseStatus
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      case {
        id
        person {
          id
          firstName
          lastName
          fullName
          picture
        }
        caseStatus {
          id
          name
        }
        caseFileNumber
      }
      engagementsData {
        notes
        emails
        calls
        documents
        letters
        texts
        in_person
        social_media
      }
      connectionsData
    }
  }
`;

export const DASHBOARD_HEATMAP_QUERY = gql`
  query dashboardHeatmap(
    $teamId: Int!
    $teamMemberIds: [Int!]!
    $caseIds: [Int!]!
    $activities: [String!]!
    $caseStatus: [Int!]!
    $dateStart: Date!
    $dateEnd: Date!
  ) {
    dashboardHeatmap(
      teamId: $teamId
      teamMemberIds: $teamMemberIds
      caseIds: $caseIds
      activities: $activities
      caseStatus: $caseStatus
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      week
      day
      value
    }
  }
`;

export const DASHBOARD_ENGAGEMENTS = gql`
  query dashboardEngagements(
    $teamId: Int!
    $teamMemberIds: [Int!]!
    $filterFormerTeamMembers: Boolean!
    $caseIds: [Int!]!
    $activities: [String!]!
    $caseStatus: [Int!]!
    $dateStart: Date!
    $dateEnd: Date!
  ) {
    dashboardEngagements(
      teamId: $teamId
      teamMemberIds: $teamMemberIds
      filterFormerTeamMembers: $filterFormerTeamMembers
      caseIds: $caseIds
      activities: $activities
      caseStatus: $caseStatus
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      ...EngagementDetail
    }
  }
  ${ENGAGEMENT_DETAIL_FRAGMENT}
`;

export const DURATION_SELECTIONS_QUERY = gql`
  query durationSelections($teamId: Int!) {
    users(teamId: $teamId) {
      id
      name
      picture
      caseRoles {
        id
        userId
        caseId
        role
        case {
          id
          teamId
          createdBy {
            id
          }
        }
      }
    }
    schema {
      caseStatus {
        id
        name
        sortOrder
        representsClosure
      }
    }
  }
`;

export const DASHBOARD_CASE_HISTORY_QUERY = gql`
  query dashboardStatusHistory(
    $teamId: Int!
    $teamMemberIds: [Int!]!
    $caseStatus: [Int!]!
    $limit: Int!
    $offset: Int!
    $orderBy: String!
  ) {
    dashboardStatusHistory(
      teamId: $teamId
      teamMemberIds: $teamMemberIds
      caseStatus: $caseStatus
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      cases {
        id
        createdBy {
          id
        }
        person {
          id
          createdAt
          firstName
          lastName
          fullName
          picture
        }
        caseFileNumber
        caseStatus {
          id
          name
        }
        history {
          id
          timestamp
          caseStatusId
          caseStatus {
            id
            name
          }
          userId
        }
      }
      hasMore
    }
  }
`;

export const AUDIT_LOGS_QUERY = gql`
  query auditLogs(
    $teamId: Int!
    $teamMemberIds: [Int!]
    $caseIds: [Int!]
    $actionTypes: [String!]
    $endDate: Date
    $searchValue: String
    $limit: Int!
    $offset: Int!
  ) {
    auditLogs(
      teamId: $teamId
      teamMemberIds: $teamMemberIds
      caseIds: $caseIds
      actionTypes: $actionTypes
      endDate: $endDate
      searchValue: $searchValue
      limit: $limit
      offset: $offset
    ) {
      auditLogs {
        id
        userId
        createdAt
        action
        teamId
        ipAddress
        clientId
        sessionId
        targetCaseId
        targetRelationshipId
        targetPersonId
        targetUserId
        targetEngagementId
        targetWorkspaceId
        values
      }
      csvDownload
      hasMore
      relationships {
        id
        person {
          fullName
          picture
        }
      }
    }
  }
`;

export const AUDIT_LOG_FRAGMENT = gql`
  fragment AuditLogDetail on AuditLog {
    id
    teamId
    userId
    ipAddress
    clientId
    sessionId
    action
    values
    targetCaseId
    targetRelationshipId
    targetPersonId
    targetUserId
    targetEngagementId
    targetWorkspaceId
  }
`;

export const DASHBOARD_TEAM_REPORTS = gql`
  query dashboardTeamReports(
    $teamId: Int!
    $teamMemberResultsLimit: Int!
    $caseLimit: Int!
    $caseReportsLimit: Int
    $caseReportsSortBy: String
  ) {
    dashboardTeamCasesWithLimit(
      teamId: $teamId, 
      caseLimit: $caseLimit
    ) {
      id
      teamId
      person {
        id
        fullName
        picture
        gender
        birthdayRaw
      }
      caseStatus {
        id
        name
        representsClosure
      }
      caseFileNumber
    }

    dashboardTeamMembersWithCaseCount(
      teamId: $teamId,
      teamMemberResultsLimit: $teamMemberResultsLimit
    ) {
      userId
      allCases
      activeCases
      inactiveCases
      closedCases
    }

    dashboardCaseReportsActivity(
      teamId: $teamId, 
      limit: $caseReportsLimit,
      sortBy: $caseReportsSortBy
    ) {
      caseId
      casePicture
      caseFirstName
      caseMiddleName
      caseLastName
      caseStatus
      caseFileNumber
      connections
      notes
      calls
      emails
      documents
      letters
      texts
      inPerson
      socialMedia
      reminders
      allActivity
    }

    dashboardReportsDuration(
      teamId: $teamId
    ) {
      name
      value
    }
  }
`;

export const DASHBOARD_CASE_REPORTS_ACTIVITY = gql`
  query dashboardCaseReportsActivity(
    $teamId: Int!
    $caseReportsLimit: Int
    $caseReportsSortBy: String
  ) {
    dashboardCaseReportsActivity(
      teamId: $teamId, 
      limit: $caseReportsLimit,
      sortBy: $caseReportsSortBy
    ) {
      caseId
      casePicture
      caseFirstName
      caseMiddleName
      caseLastName
      caseStatus
      caseFileNumber
      connections
      notes
      calls
      emails
      documents
      letters
      texts
      inPerson
      socialMedia
      reminders
      allActivity
    }
  }
`;